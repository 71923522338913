<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required } from "vuelidate/lib/validators";
import { customToastUtils } from "../../../../utils/customToast.utils";
import { notificationService } from "../../../../services/notification.service";
import { customFunction } from '../../../../utils/customFunction';
import { filesBaseUrl } from '../../../../constants/config';

export default {
    page: {
        title: "notifications",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Gestion des notifications",
            isAdd: false,
            isUpdate: false,
            isNotValid: false,
            filesBaseUrl,
            titleModel: !this.isUpdate ? 'Enregistrement' : 'Mise à jours',
            items: [
                {
                    text: "Accueil",
                    href: "/"
                },
                {
                    text: "Notifications",
                    active: true
                }
            ],
            notificationData: [],
            currentPage: 1,
            totalRows: 1,
            perPage: 5,
            pageOptions: [5, 10, 25, 50],
            filter: null,
            filterOn: [],
            sortBy: "id",
            sortDesc: false,
            fields: [
                { key: "image", sortable: true, label: "Image" },
                { key: "titre", sortable: true, label: "Titre" },
                { key: "contenu", sortable: true, label: "Contenu" },
                { key: "destinataire", sortable: true, label: "Destinataire" },
                { key: "created", sortable: true, label: "Date envoi" },
                { key: "action" }
            ],
            notificationForm: {
                titre: "",
                contenu: "",
                destinataire: []
            },
            submitted: false,
            imageNotification: null,
            showForm: false
        };
    },
    validations: {
        notificationForm: {
            titre: { required },
            contenu: { required }
        },
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.notificationData.length;
        },

    },
    mounted() {
        this.getNotification();
        this.totalRows = this.notificationData.length;

    },
    methods: {

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        verifAdmin() {
            return customFunction.verifAdmin();
        },  
        showAddForm(show) {
            this.isAdd = show;
            if (!show) {
                this.clearForm();
                this.submitted = false;
                this.isUpdate = false;
            }
        },

        formSubmit() {
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$error) {
                this.$bvModal.show('modal-center');
            }
        },

        async formValidate() {
            if (this.notificationForm.contenu.trim() == "") {
                customToastUtils.error(this.$bvToast, 'Enregistrement', 'Le contenu est requis');
                return;
            }
            let notificationF = new FormData();
            notificationF.append("titre", this.notificationForm.titre);
            notificationF.append("contenu", this.notificationForm.contenu);
            if (this.notificationForm.destinataire.length > 0) {
                this.notificationForm.destinataire.forEach(element => {
                     notificationF.append("destinataire", element);
                });
            }
            if (this.imageNotification != null) notificationF.append("image", this.imageNotification.file);

            let resp = this.isUpdate ? await notificationService.update(notificationF, this.notificationForm.id) : await notificationService.create(notificationF);

            if (resp >= 400) {
                customToastUtils.error(this.$bvToast, 'Enregistrement', 'Une erreur s\'est produite. Veuillez réssayer');
            } else {
                this.clearForm();
                this.isAdd = false;
                customToastUtils.success(this.$bvToast, 'Enregistrement', 'Enregistrement reussi');
                await this.getNotification();
            }
        },

        async getNotification() {
            this.notificationData = await notificationService.get();
        },

        clearForm() {
            this.$v.$reset();
            this.notificationForm.titre = '';
            this.notificationForm.contenu = '';
            this.notificationForm.destinataire = [];
            this.imageNotification = null;
        },

        showUpdateForm(row) {
            this.notificationForm.titre = row.titre;
            this.notificationForm.contenu = row.contenu;
            this.notificationForm.destinataire = row.destinataire;
            this.notificationForm.id = row.id;
            this.isAdd = true;
            this.isUpdate = true;
        },
        showDeleteModal(item) {
            this.deleteId = item.id;
            this.$bvModal.show('delete-modal-center');

        },
        async deleteData() {

            let resp = await notificationService.del(this.deleteId);

            if (resp >= 400) {
                customToastUtils.error(this.$bvToast, 'Suppression', 'Une erreur s\'est produite. Veuillez réssayer');
            } else {
                await this.getNotification();
            }
            this.deleteId = null;
        },
        fileDeleted: function (fileRecord) {
            var i = this.imageNotification.indexOf(fileRecord);
            if (i !== -1) {
                this.imageNotification.splice(i, 1);
            } else {
                this.deleteUploadedFile(fileRecord);
            }
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row mb-2">
            <div v-if="!isAdd && verifAdmin()" class="col-md-8">
                <button @click="isAdd = true" class="btn btn-primary">Ajouter</button>
            </div>
        </div>
        <br>
        <div v-if="isAdd" class="card">
            <!-- <div class="card-header">Envoyer une notification</div> -->
            <div class="col-md-12  card-body">
                <form class="row needs-validation" @submit.prevent="formSubmit">

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="validationCustom01">Titre</label>
                            <input id="validationCustom01" v-model="notificationForm.titre" type="text" class="form-control"
                                placeholder="Titre" :class="{ 'is-invalid': submitted && $v.notificationForm.titre.$error }" />
                            <div v-if="submitted && $v.notificationForm.titre.$error" class="invalid-feedback">
                                <span v-if="!$v.notificationForm.titre.required">Ce champs est requis.</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="validationCustom01">Contenu</label>
                            <textarea 
                                name="" class="form-control" id="" v-model="notificationForm.contenu" cols="30" rows="2"
                                :class="{
                                    'is-invalid': submitted && $v.notificationForm.contenu.$error,
                                }"
                                ></textarea>
                            <div
                                v-if="submitted && $v.notificationForm.contenu.$error" class="invalid-feedback">
                                <span v-if="!$v.notificationForm.contenu.required"
                                >Champs requis.</span>
                            </div>
                            
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="">Image</label>
                            <VueFileAgent @delete="fileDeleted($event)" v-model="imageNotification" :multiple="false"
                                :deletable="true" :accept="'image/png, image/jpeg, image/jpg'" :maxSize="'3MB'"
                                :maxFiles="1" :helpText="'Sélectionner l\'image'" :errorText="{
                                    type: 'Type de fichier invalid',
                                    size: 'Votre fichier ne peux excéder 3Mo',
                                }">
                            </VueFileAgent>
                        </div>
                    </div>

                    <div class="col-md-12 mt-4">
                        <b-button variant="primary" @click="formSubmit">
                            <template>
                                <span v-if="isAdd && !isUpdate">Envoyer</span>
                                <span v-if="isAdd && isUpdate">Modifier</span>
                            </template>
                        </b-button>
                        <button class="btn btn-secondary ml-2" @click="showAddForm(false)">Annuler</button>
                    </div>
                    <b-modal id="modal-center" centered :title=titleModel title-class="font-18" @ok="formValidate">
                        <p>
                            Confirmez-vous l'action?
                        </p>
                    </b-modal>
                </form>
            </div>
        </div>
        <div class="card">
            
            <div class="card-body">
                <div class="row">
                    <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show
                                        <b-form-select class="form-control form-control-sm form-select form-select-sm"
                                            v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                                            class="form-control form-control-sm ml-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                    <div class="table-responsive">
                        <b-table :items="notificationData" :fields="fields" responsive="sm" :per-page="perPage"
                            :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                            :filter-included-fields="filterOn" @filtered="onFiltered" show-empty
                            :empty-text="'Aucun resultat'" :empty-filtered-text="'Aucun resultat'">
                            <!-- <template v-slot:cell(action)="row">

                                <a href="javascript:void(0);" @click="showUpdateForm(row.item)" class="mr-3 text-primary"
                                    v-b-tooltip.hover data-toggle="tooltip" title="Modifier">
                                    <i class="mdi mdi-pencil font-size-18"></i>
                                </a>
                                <a href="javascript:void(0);" @click="showDeleteModal(row.item)" class="text-danger"
                                    v-b-tooltip.hover title="Supprimer">
                                    <i class="mdi mdi-trash-can font-size-18"></i>
                                </a>
                            </template> -->
                            <template v-slot:cell(image)="r">
                                <img :src="`${filesBaseUrl}/notification/${r.item.image}`" height="50" alt="">
                            </template>
                            <template v-slot:cell(contenu)="r">
                                <p style="height: 50px; text-overflow: ellipsis;" v-html="r.item.contenu"></p>

                            </template>
                             <template v-slot:cell(created)="r">
                                    {{ r.item.created | defautTableDateTIme }}
                                </template>
                        </b-table>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded mb-0">
                                    <!-- pagination -->
                                    <b-pagination v-model="currentPage" :total-rows="rows"
                                        :per-page="perPage"></b-pagination>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="delete-modal-center" centered :title="'Supression'" title-class="font-18" @ok="deleteData">
            <p>
                Confirmez-vous l'action?
            </p>
        </b-modal>
    </Layout>
</template>
