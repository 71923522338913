import errorHandler from './handler/erroHandler';
import axiosInstance from './manager/manager.service';
import { baseUrl } from "../constants/config";

export const notificationService = {
    create,
    update,
    get,
    del
};

async function create(actu) {

    try {
        let ret = await axiosInstance.post(`${baseUrl}/notifications`, actu);

        return ret.data;
    } catch (error) {
        return errorHandler(error);
    }
}

async function get() {

    try {
        let ret = await axiosInstance.get(`${baseUrl}/notifications`);

        return ret.data;
    } catch (error) {
        console.log(error);
        return [];
    }

}

async function update(data, id) {
    try {
        let ret = await axiosInstance.put(`${baseUrl}/notifications/${id}`, data);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}


async function del(id) {
    try {
        let ret = await axiosInstance.delete(`${baseUrl}/notifications/${id}`);
        return ret.data;
    } catch (error) {
        console.log(error);
        return errorHandler(error);
    }
}


